<template >
    <div class="cert">
        <div class="logButton clearFix">
            <div class="export">
                <el-button class="confirmButton" @click="showaddCert">添加证书</el-button>
            </div>
            <div class="search">
                <!-- 搜索题目 -->
                <div class="searchContent">
                    <el-input placeholder="请输入证书名称" v-model="search" clearable @clear="handleSearchColleague" class="input-with-select"></el-input>
                </div> 
                <el-button class="confirmButton" @click="handleSearchColleague">搜索</el-button>
            </div>
            <div class="searchschool">
              <el-select   
                clearable
                v-model="searchschool_id" 
                filterable  
                placeholder="请搜索选择学校名称"  
                :filter-method="getsearchschoolList"
                @change="handleSearchColleague">
                  <el-option
                    v-for="item in searchschoolList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
            </div>
        </div>
        <!-- 证书列表 -->
        <el-card>
            <el-table
                ref="multipleTable"
                :data="certList"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                      type="index"
                      :index="indexMethod"
                      label="序号"
                      width="100"> 
                    </el-table-column>
                    <!--证书名称  -->
                    <el-table-column
                      prop="certname"
                      label="证书名称">
                    </el-table-column>
                    <el-table-column
                      prop="school_name"
                      label="学校">
                    </el-table-column>
                    <el-table-column
                      label="状态">
                      <template slot-scope="scope">
                      <el-switch
                        @change="updateStatusCert(scope.row.idnum,scope.row.status)"
                        v-model="scope.row.status"
                        active-color="#0D5570"
                        inactive-color="#CCCCCC">
                      </el-switch>
                    </template>
                    </el-table-column>
                    <el-table-column
                      prop="getCertNumber"
                      label="获取人数">
                    </el-table-column>
                    
                    <el-table-column
                    label="操作">
                        <template slot-scope="scope">
                            <div class="operatorItem floatLeft" @click="updateCert(scope.row)">编辑</div>
                        </template>
                    </el-table-column>
            </el-table>
            <!-- 证书分页 -->
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="page"
                background
                :page-size="pagesize"
                layout="prev, pager, next"
                :total="certTotal">
            </el-pagination>
        </el-card>
        <el-dialog
          class="addcertBox"
          :title="certTitle"
          :visible.sync="certVisible"
          width="60%"
          >
          <el-form :model="certForm" :rules="certRules" ref="ruleForm" label-width="10vw" label-position="left" class="demo-ruleForm" >
            <el-form-item label="证书名称" prop="name">
              <el-input placeholder="请输入证书名称" v-model="certForm.name"></el-input>
            </el-form-item>
            <el-form-item label="证书文案" prop="title">
              <el-input placeholder="请输入证书文案" v-model="certForm.title"></el-input>
            </el-form-item>
            <el-form-item label="发证单位(选填)" >
              <el-input placeholder="请输入发证单位" v-model="certForm.mechan"></el-input>
            </el-form-item>
            <el-form-item label="学校名称" prop="school_id">
              <el-select   
                clearable
                v-model="certForm.school_id" 
                filterable  
                placeholder="请搜索选择学校名称"  
                :filter-method="getSchoolList">
                  <el-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="老师姓名(选填)" >
              <el-select   
                clearable
                v-model="certForm.school_teacher" 
                filterable  
                placeholder="请搜索选择老师姓名(证书类型为项目时)"  
                :filter-method="getTeacherList" @change="getProjectListNew">
                  <el-option
                    v-for="item in teacherList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <!-- 证书类型 -->
            <!-- <el-form-item label="证书类型">
              <el-radio-group v-model="type" @change="selectType">
              <el-radio label="project">项目</el-radio>
              <el-radio label="game">小游戏</el-radio>
              <el-radio label="exp">虚拟实验</el-radio>
            </el-radio-group>
            <div v-show="type=='project'" >
              <el-select   
              clearable
                v-model="certForm.project_id" 
                filterable  
                placeholder="请搜索选择项目"  
                :filter-method="getProject">
                  <el-option
                    v-for="item in projectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
            </div>
            <div v-show="type=='game'" >
              <el-select   
                v-model="certForm.minigame_id" 
                placeholder="请选择小游戏名称"  >
                  <el-option
                    v-for="item in gamenameList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
              <el-select 
              clearable
              v-show="certForm.minigame_id"  
                v-model="certForm.game_type" 
                filterable  
                placeholder="请选择小游戏类型"  
                @change="getgameLevel('clear')"
                @focus="getgameType"
                @clear="cleargameType">
                  <el-option
                    v-for="item in gametypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
              <el-select   
              clearable
              multiple
              @focus="getgameLevel"
              v-show="certForm.game_type"  
                v-model="certForm.game_cid" 
                filterable  
                placeholder="请选择小游戏关卡">
                  <el-option
                    v-for="item in gamelevelList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
            </div>
            <div v-show="type=='exp'" >
              <el-select   
              clearable
              multiple
                v-model="certForm.exp_idArr" 
                filterable  
                placeholder="请搜索选择虚拟实验"  
                :filter-method="getExp">
                  <el-option
                    v-for="item in expList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
            </div>
            </el-form-item> -->
            
            <!-- 项目列表（多选） -->
            <el-form-item label="项目列表(选填)">
              <el-select v-model="certForm.project_id"  class="el-input" multiple placeholder="请选择(在项目管理中设置考试规则)">
                <el-option
                  v-for="item in projectListNew"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 考试通过率 -->
            <el-form-item label="考试通过率(选填)">
              <el-input v-model="certForm.exam_rate"></el-input>
            </el-form-item>
            
            <el-form-item label="虚拟实验列表(选填)">
              <p style="margin-bottom: 15px;"> 
                <el-button type="primary" @click="addExpLine" class="themeButton">新增虚拟实验+</el-button>
              </p>
              <p>
                <!-- 这是一行数据 -->
                <span class="exam-line" v-for="(val, ind) in certForm.exp_idArr" :key="ind"  >
                  <!-- 单选 -->
                  <span class="examBox-new">
                    <span class="title">虚拟实验id:</span>
                    <span><el-input v-model="val.exp_id" placeholder="请输入虚拟实验id" type="text" style="width: 10vw !important;"></el-input></span>
                  </span>
                  <!-- 多选 -->
                  <span class="examBox-new">
                    <span class="title left">关卡配置:</span>
                    <span><el-input v-model="val.CID" placeholder="关卡用字符标记例如:1,3,5,7,9" type="text" style="width: 15vw !important;"></el-input></span>
                  </span>
                  <span style="margin-left:10px;" >
                    <i class="el-icon-close" style="color: red;cursor: pointer;" @click="deleteExpByIndex(ind)"></i>
                  </span>
                </span>
              </p>
            </el-form-item>

            <!-- 虚拟实验通过率 -->
            <el-form-item label="虚拟实验通过率(选填)">
              <el-input v-model="certForm.accuracy_rate"></el-input>
            </el-form-item>

            <el-form-item label="数据开始日期(选填)">
              <el-date-picker
                class="el-input"
                v-model="certForm.dataStartDate"
                type="date"
                placeholder="请选择数据开始日期"
                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="数据结束日期(选填)">
              <el-date-picker
                class="el-input"
                v-model="certForm.dataEndDate"
                type="date"
                placeholder="请选择数据结束日期"
                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="领取截止日期(选填)">
              <!-- <el-input v-model="certForm.closingDate"></el-input> -->
              <el-date-picker
                class="el-input"
                v-model="certForm.closingDate"
                type="date"
                placeholder="请选择截止日期"
                value-format="yyyy-MM-dd">
                </el-date-picker>
              <!-- <el-date-picker
              
                    v-model="certForm.closingDate"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="—"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
            ></el-date-picker> -->
            </el-form-item>
            <el-form-item label="证书描述(选填)">
              <el-input v-model="certForm.des"></el-input>
            </el-form-item>
            <el-form-item label="学校证书">
              <el-radio v-model="certForm.is_school_cert" label="0">否</el-radio>
              <el-radio v-model="certForm.is_school_cert" label="1">是</el-radio>
            </el-form-item>
          </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button class="themecancelButton" @click="certVisible = false">取消</el-button>
              <el-button class="themeButton" @click="addCert">确定</el-button>
            </span >
            
        </el-dialog>


    </div>
  
</template>

<script>
    // 引入试题列表js
    import cert from "@/assets/js/manager/cert/cert.js";
    export default {
     ...cert
    }
</script>

<style>
.cert .confirmButton{
    padding: 0 .7vw !important;
    height: 2.0833vw!important;
    background-color: #0D5570!important;
    color:#FFFFFF!important;
    border-radius: 0.2083vw!important;
    font:normal normal normal 0.8333vw  Source Han Sans CN!important;
}
.cert .el-input__inner {
    font-size: 0.8333vw;
    height: 2.0833vw;
}
.cert .el-input,.cert .el-select{
    width:20vw!important;
}
.cert .el-form--label-left .el-form-item__label {
    font-size: 0.8333vw;
}
.cert .cancelButton{
    padding: 0 .7vw!important;
    height: 2.0833vw!important;
    background-color: #FFFFFF!important;
    color:#000000!important;
    border: 0.0521vw solid #CCCCCC!important;
    border-radius: 0.2083vw!important;
    font:normal normal normal 0.8333vw  Source Han Sans CN!important;
}
.cert .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
.cert .el-dialog{
  border-radius: 0.625vw !important;
  margin-top: 5vh !important;
}
.cert .el-table__body-wrapper {
    height: 67vh;
    overflow: scroll;
    position: relative;
    scrollbar-width: none;
            -ms-overflow-style: none;
            overflow: scroll;
}

.cert .el-form{
    max-height: 66vh;
    overflow: scroll;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-radius: 0.625vw !important;
}
.cert .el-table__body-wrapper::-webkit-scrollbar,.cert .el-form::-webkit-scrollbar{
  display: none;
}
.cert .el-upload {
        border: 1px dashed #d9d9d9 !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
.cert .avatar-uploader{
  /* text-align:center; */
}
.cert .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.cert .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.cert .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.cert .searchschool .el-input,.cert .searchschool .el-select{
  width: 12vw !important;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/manager/cert/cert.scss";
</style>