import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { encryptCode,decryptCode } from "@/utils/function";
import {request_sign} from '@/utils/const';
import API from '@/api/manager/cert/cert.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
        // 页面名称
        name: "cert",
        data()
        {
            return{
                // 项目id
                projectIdArray:[],
                // 项目列表
                projectListNew:[],
                searchschool_id:'',
                searchschool_name:'',
                searchschoolList:[],
                // 证书编号
                idnum:'',
                // 证书列表
                certList:[],
                // 项目列表(证书类型使用)
                projectList:[],
                expList:[],
                // 搜索证书关键词
                search:'',
                page:1,
                pagesize:10,
                certVisible:false,
                certTitle:'',
                certTotal:0,
                certForm:{
                    exam_rate:'',
                    // 学校级别证书 0：否  1：是
                    is_school_cert:'',
                    name:'',
                    title:'',
                    // 发证单位
                    mechan:'',
                    school_id:'',
                    school_teacher:'',
                    game_cid:'',
                    game_type:'',
                    project_id:'',
                    // 证书类型虚拟实验ID
                    exp_idArr:[],
                    minigame_id:1,
                    accuracy_rate:'',
                    closingDate:'',
                    dataStartDate:'',
                    dataEndDate:'',
                    status:1,
                    des:'' 
                },
                // 证书表单验证规则
                certRules:{
                    name: [
                        { required: true, message: '请输入证书名称', trigger: 'blur' },
                    ],
                    title: [
                        { required: true, message: '请输入证书文案', trigger: 'blur' },
                    ],
                    mechan: [
                        { required: true, message: '请输入发证单位', trigger: 'blur' },
                    ],
                    school_id: [
                        { required: true, message: '请选择学校', trigger: 'blur' },
                    ],
                },
                // 证书类型
                type:'',
                // 学校列表
                schoolList:[],
                // 老师列表
                teacherList:[],
                // 小游戏名称列表
                gamenameList:[
                    {
                        id: 1,
                        name: "实验室安全教育"
                    },
                    // {
                    //     id: 2,
                    //     name: "知青岁月"
                    // },
                    // {
                    //     id: 3,
                    //     name: "自由电子流"
                    // },
                    // {
                    //     id: 4,
                    //     name: "文物修复"
                    // },
                    // {
                    //     id: 5,
                    //     name: "Mlabs"
                    // },
                ],
                gametypeList:[],
                gamelevelList:[],
            }
        },
        created()
        {
            let self=this;
            if(this.$router.currentRoute.query.params)
            {
                let params = JSON.parse(decryptCode(this.$router.currentRoute.query.params));
                self.page = Number(params?.page); 
                self.search = params?.search;
                self.searchschool_id= params?.school_id;
                self.searchschool_name =  params?.school_name;
                if(self.searchschool_name)
                {
                    self.searchschoolList.push({
                        id: self.searchschool_id,
                        name: self.searchschool_name,
                    })
                }
            }
            self.getCertList();
        },
        methods:
        {
            deleteExpByIndex(index)
            {
                const self = this;
                self.certForm.exp_idArr.splice(index,1);
            },
            addExpLine()
            {
                const self = this;
                self.certForm.exp_idArr.push(
                    {
                        // 虚拟实验id
                        exp_id:'',
                        // 关卡配置
                        CID:'',
                    }
                );
            },
            getProjectListNew()
            {
                const self = this;
                self.projectListNew = [];
                let str = encryptCode({
                    user_id:self.certForm.school_teacher
                });
                let data = {sign:request_sign,params:str};
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.getProjectListNew(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) 
                        {
                            self.projectListNew = result.list;
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })

            },
            getCertList()
            {
                let self = this;
                let str = encryptCode({
                    search:self.search,
                    school_id:self.searchschool_id,
                    page:self.page,
                    pagesize :self.pagesize
                })
                let params = encryptCode({
                    search: self.search,
                    page:self.page,
                    school_id:self.searchschool_id,
                    school_name:self.searchschool_name
                })
                self.$router.push({
                    path: "/manager/cert",
                    query: {
                        params:params
                    },
                });
                let data = {sign:request_sign,params:str};
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.getCertList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            result.data?.forEach((item)=>{
                                item.status = item.status?true:false
                            })
                            self.certList = result.data
                            self.certTotal = result.total
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            },
            handleSearchColleague()
            {
                let self = this;
                self.page = 1;
                let school = self.searchschoolList.find((item)=>{
                    return item.id == self.searchschool_id
                })
                self.searchschool_name = school?.name
                self.getCertList();
            },
            getgameName(e)
            {
                if(!e)
                {
                    return;
                }
                this.getCertType(this.type,this.certForm.school_teacher,e)
            },
            cleargameName()
            {
                this.certForm.game_type = '';
                this.certForm.game_cid = []
            },
            cleargameType()
            {
                this.certForm.game_cid = []
            },
            /**
             * @name: 切换证书类型
             */
            selectType(e)
            {
                this.certForm.project_id = '';
                this.certForm.minigame_id = 1;
                this.certForm.game_type = '';
                this.certForm.game_cid = [];
                this.certForm.exp_idArr = [];
                // if(this.type == 'game')
                // {
                //     this.getgameType()
                // }
            },
            getgameType()
            {
                let self = this;
                if(!self.certForm.minigame_id)
                {
                    return;
                }
                let str = encryptCode({
                    minigame_id:self.certForm.minigame_id,
                })
                let data = {sign:request_sign,params:str}
                // 获取虚拟实验分类请求
                API.getGameType(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.gametypeList = result.data
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            },
            getgameLevel(e)
            {
                let self = this;
                if(e == 'clear')
                {
                    self.certForm.game_cid = [];
                }
                
                if(!self.certForm.game_type)
                {
                    return;
                }
                let str = encryptCode({
                    gameType:self.certForm.game_type,
                })
                let data = {sign:request_sign,params:str}
                // 获取虚拟实验分类请求
                API.getGameLevel(data)
                    .then((result) => {
                        // self.$Loading.hide();
                        if (result.code > 0) {
                            self.gamelevelList = result.data
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            },
            getsearchschoolList(e)
            {
                let self=this;
                // 请求参数
                let data = {
                    name : e,
                };
                // 获取学校列表请求
                API.getSchoolList(data)
                    .then((result)=>{
                        if(result.code > 0 )
                        {
                            self.searchschoolList = result.list
                        }
                    })                
            },
            getSchoolList(e)
            {
                let self=this;
                // 请求参数
                let data = {
                    name : e,
                };
                // 获取学校列表请求
                API.getSchoolList(data)
                    .then((result)=>{
                        if(result.code > 0 )
                        {
                            self.schoolList = result.list
                        }
                    })                
            },
            getTeacherList(e)
            {
                let self = this;
                // 请求参数
                let data = {
                    searchValue: e,
                }
                nprogressStart();
                self.$Loading.show();
                // 获取用户请求
                API.getTeacherList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.teacherList = result.list;
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })   
            },
            getCertType(type,teacherID,name)
            {
                let self = this;
                let str = encryptCode({
                    table:type,
                    teacherID:teacherID,
                    name :name
                })
                let data = {sign:request_sign,params:str};
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.getCertType(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            if(type == 'project')
                            {
                                self.projectList = result.data
                            }
                            else if(type == 'exp')
                            {
                                self.expList = result.data
                            }
                            else if(type == 'game')
                            {
                                self.gamenameList = result.data
                            }
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            },
            getProject(e)
            {
                if(!e)
                {
                    return;
                }
                this.getCertType(this.type,this.certForm.school_teacher,e)
            },
            getExp(e)
            {
                if(!e)
                {
                    return;
                }
                this.getCertType(this.type,this.certForm.school_teacher,e)
            },
            updateCert(info)
            {
                let self = this;
                self.type = '';
                self.schoolList = [];
                self.teacherList = [];
                self.gametypeList = [];
                self.gamelevelList = [];
                self.projectList = [];
                self.certForm.school_teacher = '';
                self.expList = [];
                self.projectListNew = [];
                self.certForm.exp_idArr = [];
                self.schoolList.push({
                    id:info.school_id,
                    name:info.school_name
                });
                self.teacherList.push({
                    id:info.teacher_id,
                    name:info.teacherName
                });
                self.idnum = info.idnum;
                self.certForm.name = info.certname;
                self.certForm.mechan = info.mechan;
                self.certForm.school_id = info.school_id;
                self.certForm.accuracy_rate = info.accuracy_rate;
                self.certForm.des = info.des;
                self.certForm.school_teacher = info.teacher_id;
                if(info.exp_id)
                {
                    self.type = 'exp'
                }
                else if(info.project_id)
                {
                    self.type = 'project'
                }
                else if(info.minigame_id)
                {
                    self.type = 'game'
                }
                let table = '';
                let typeID = '';
                if(info.exp_id)
                {
                    table='exp';
                    typeID=info.exp_id
                }
                else if (info.project_id)
                {
                    table='project'
                    typeID=info.project_id
                }
                else
                {
                    table='game';
                    typeID=info.minigame_id
                }
                let str = encryptCode({
                    table:table,
                    idnum:info.idnum,
                    typeID:typeID,
                    game_type:info.game_type,
                    game_cid:info.game_cid
                })
                let data = {sign:request_sign,params:str}
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.getCertDetail(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.projectListNew = result.list;
                            self.gametypeList.push({
                                id:info.game_type-0,
                                name:result.gameTypeName[0]?.name
                            })
                            if(!info.game_cid=="")
                            {
                                let arr = info.game_cid.split(',').sort(function (a, b) {
                                    return a - b
                                });
                                arr?.forEach((item,index)=>{
                                    self.gamelevelList.push({
                                        id:item-0,
                                        name:result.gamelevelName[index]?.name
                                    })
                                })
                                self.certForm.game_cid = info.game_cid.split(',').sort(function (a, b) {
                                    return a - b
                                });;
                                self.certForm.game_cid?.forEach((item,index)=>{
                                    self.certForm.game_cid[index] = Number(item);
                                })
                            }
                            // 虚拟实验列表
                            self.certForm.exp_idArr = result.data[0].exp_id;
                            
                            if(!info.project_id=="")
                            {
                                let name = '';
                                if(result.name.length > 0)
                                {
                                    name = result.name[0].name ?? '';
                                }
                                self.projectList.push({
                                    id:info.project_id,
                                    name:name
                                    // name:result.name[0].name 
                                })
                            }
                            if(info.game_type != 0)
                            {
                                self.certForm.game_type = info.game_type -0
                            }
                            // 领取证书截止日期
                            let datetime=result.data[0].close_date
                            if(result.data[0].close_date)
                            {
                                let timestr = new Date(parseInt(datetime) * 1000);
                                let year = timestr.getFullYear();
                                let month = timestr.getMonth()+1;
                                let date = timestr.getDate();
                                let close_date = year+"-"+month+"-"+date;
                                self.certForm.closingDate = close_date;
                            }
                            else
                            {
                                self.certForm.closingDate = result.data[0].close_date;
                            }
                            // 证书数据开始时间
                            let dataStartDate = result.data[0].data_start_time;
                            self.certForm.dataStartDate = dataStartDate;
                            
                            // 证书数据结束时间
                            let dataEndDate = result.data[0].data_end_time
                            self.certForm.dataEndDate = dataEndDate;
                            self.certForm.project_id = result.data[0].project_id;
                            self.certForm.title = result.data[0].title;
                            self.certForm.des = result.data[0].des;
                            self.certForm.is_school_cert = result.data[0].is_school_cert;
                            self.certForm.exam_rate = result.data[0].exam_rate;
                            self.certVisible = true;
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                        console.log(self.certForm.exp_idArr)
                    })
            },
            addCert()
            {
                let self = this;
                if(!self.certForm.name || !self.certForm.title || !self.certForm.school_id)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请输入完整信息',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                /*if(!self.type)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请选择证书类型',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }//*/
                // self.certForm.exp_idArr = self.certForm.exp_idArr.toString();
                self.certForm.game_cid = self.certForm.game_cid.toString();
                let str = encryptCode({
                    idnum:self.idnum,
                    ...self.certForm
                })
                let data = {sign:request_sign,params:str}
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.updateCert(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.certVisible = false;
                            self.certForm.name = '';
                            self.idnum = '';
                            self.certForm.title = '';
                            self.certForm.mechan = '';
                            self.certForm.school_id = '';
                            self.type = '';
                            self.certForm.project_id = '';
                            self.certForm.game_type = '';
                            self.certForm.game_cid = [];
                            self.certForm.exp_idArr = [];
                            self.certForm.accuracy_rate = '';
                            self.certForm.closingDate = '';
                            self.certForm.des = '';
                            self.certForm.dataStartDate = '';
                            self.certForm.dataEndDate = '';
                            self.schoolList = [];
                            self.projectList = [];
                            self.gametypeList = [];
                            self.gamelevelList = [];
                            self.expList=[];
                            self.getCertList();
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            },
            updateStatusCert(idnum,status)
            {
                let self = this;
                let str = encryptCode({
                    idnum:idnum,
                    status:status?'1':'0',
                })
                let data = {sign:request_sign,params:str}
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.updateStatusCert(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })

            },
            showaddCert()
            {
                this.certForm.name = '';
                this.idnum = '';
                this.certForm.title = '';
                this.certForm.mechan = '';
                this.certForm.school_id = '';
                this.type = '';
                this.certForm.project_id = '';
                this.certForm.game_type = '';
                this.certForm.game_cid = [];
                this.certForm.exp_idArr = [];
                this.certForm.accuracy_rate = '';
                this.certForm.closingDate = '';
                this.certForm.des = '';
                this.certVisible = true;
            },
            indexMethod(index) {
                let currentPage = this.page; // 当前页码
                let pageSize = this.pagesize; // 每页条数
                return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
            },
            handleCurrentChange(val) {
                const self = this;
                self.page = val;
                self.getCertList();
            },
        }       
};