import { request } from "@/request/http";

export default {
  getSchoolList: (data) => {
    return request("POST", `/index.php/adminSchool/getSchoolList`, data);
  },
  getTeacherList: (data) => {
    return request("POST", `/index.php/backStatistics/getTeacherList`, data);
  },
  getCertList: (data) => {
    return request("POST", `/index.php/adminCert/getCertList`, data);
  },
  getCertType: (data) => {
    return request("POST", `/index.php/adminCert/getCertType`, data);
  },
  getGameType: (data) => {
    return request("POST", `/index.php/adminCert/getGameType`, data);
  },
  getGameLevel: (data) => {
    return request("POST", `/index.php/adminCert/getGameLevel`, data);
  },
  updateCert: (data) => {
    return request("POST", `/index.php/adminCert/updateCert`, data);
  },

  updateStatusCert: (data) => {
    return request("POST", `/index.php/adminCert/updateStatusCert`, data);
  },
  
  getCertDetail: (data) => {
    return request("POST", `/index.php/adminCert/getCertDetail`, data);
  },

  getProjectListNew: (data) => {
    return request("POST", `/index.php/adminCert/getProjectListNew`, data);
  }
};